import {useEffect, useRef, useState, forwardRef, useImperativeHandle} from "react";
import mapboxgl from "mapbox-gl";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import clsx from "clsx";
import { CircularProgress } from "@material-ui/core";


export const MapboxMap = forwardRef(({
    children,
    settings,
    minValueRef,
    maxValueRef,
    className,
    coordinates,
    centerArea,
    onCoordinatesChange,
    drawnCoordinates,
    polyResult,
    onClickEditBtn,
    onClickPlot,
    updateTotalCount,
  }, ref) => {
  useImperativeHandle(ref, () => {
    return {
      addEditablePolygon: addEditablePolygon,
      changeModeToSelected: changeModeToSelected
    };
  })
  const mapBoxKey =
      "pk.eyJ1IjoiaWFtYWJkdWxhbGkiLCJhIjoiY2xueGtmOXY0MGloOTJpbWNsMDJvYmZrOSJ9.gYpEtxwJP3qIiiPLpyy7Jg";

  const mapContainer = useRef(null);
  const drawRef = useRef(null);
  const [mapStyle, setMapStyle] = useState("mapbox://styles/mapbox/streets-v11");
  const [loading, setLoading] = useState(true); // Loading state
  const [map, setMapRef] = useState(null); // State variable to hold the map instance
  const [featureId, setFeatureId] = useState(null); // State variable to hold the FeatureID

  const handleDrawCreate = (event) => {
    const polygon = event.features[0];
    const updatedCoordinates = getTrimmedCoordinates(polygon);
    onCoordinatesChange(updatedCoordinates);
    // console.log(updatedCoordinates)
    const minValue = minValueRef.current;
    const maxValue = maxValueRef.current;
    if (minValue >= 0 && maxValue > 0) {
      updateTotalCount();
    }
  };
  const handleDrawUpdate = (event) => {
    const polygon = event.features[0];
    const updatedCoordinates = getTrimmedCoordinates(polygon);
    onCoordinatesChange(updatedCoordinates);

  };
  const handleModeChange = (event) => {
    if (event.mode === "draw_polygon") {
      // Accessing the current value of minValueRef and maxValueRef
      const minValue = minValueRef.current;
      const maxValue = maxValueRef.current;
      if (minValue >= 0 && maxValue > 0) {
        updateTotalCount();
      }
    }
  };

  // FUnction for editable polygon
  const addEditablePolygon = () => {
    // console.log("addEditablePolygon Called ")
    if (!map || !drawRef.current) return;
    // Ensure map and drawRef are available

    // Remove the previously drawn square, if any
    drawRef.current.deleteAll();

    // Get the dimensions of the map container
    const { width, height } = mapContainer.current.getBoundingClientRect();

    // Calculate the center of the map viewport in pixels
    const centerPixelX = width / 2;
    const centerPixelY = height / 2;

    // Convert the center pixel coordinates to geographical coordinates
    const centerCoordinates = map.unproject([centerPixelX, centerPixelY]);

    // Calculate the size of the square
    const size = 0.01; // Adjust the size of the square as needed
    const halfSize = size / 2;

    // Calculate the latitude and longitude values of the square corners
    const topLatitude = Math.min(centerCoordinates.lat + halfSize, 90);
    const bottomLatitude = Math.max(centerCoordinates.lat - halfSize, -90);
    const leftLongitude = centerCoordinates.lng - halfSize;
    const rightLongitude = centerCoordinates.lng + halfSize;

    // Define the coordinates of the square
    const topLeft = [leftLongitude, topLatitude];
    const topRight = [rightLongitude, topLatitude];
    const bottomRight = [rightLongitude, bottomLatitude];
    const bottomLeft = [leftLongitude, bottomLatitude];

    // Draw the square polygon
    drawRef.current.add({
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [[
          topLeft,
          topRight,
          bottomRight,
          bottomLeft,
          topLeft // Closing the square !important
        ]]
      }
    });

    // Get the last drawn feature (polygon) from the Draw instance
    const tempFeature = drawRef.current.getAll().features[drawRef.current.getAll().features.length - 1];
    setFeatureId(tempFeature.id);
    // Start the editing mode for the last drawn feature
    drawRef.current.changeMode("direct_select", { featureId: tempFeature.id });

  };

  const changeModeToSelected = () => {
    if (!drawRef.current) return;
    drawRef.current.changeMode("simple_select", { featureId });
  };

  useEffect(() => {
    mapboxgl.accessToken = mapBoxKey;
    const newMap = new mapboxgl.Map({
      container: mapContainer.current,
      style: mapStyle,
      center: centerArea,
      zoom: 12,
      attributionControl: false,
    });
    setMapRef(newMap);

    // Initialize Mapbox Draw
        if (!drawRef.current) {

    const draw = new MapboxDraw({
      displayControlsDefault: false,
      controls: {
        polygon: false,
        trash: false,
      },
    });


    settings && newMap.addControl(draw);

    // Store the draw instance for later use
    drawRef.current = draw;
    } else {
      // If draw instance already exists, re-add it to the map
      newMap.addControl(drawRef.current);
    }
    newMap.on("load", () => {
      // Draw polygon if coordinates are provided
      if (coordinates) {
        drawPolygon(newMap, coordinates);
      }
      if (drawnCoordinates) {
        drawPolygon(newMap, drawnCoordinates, "drawn");
      }
      if (polyResult && polyResult.length > 0) {
        // Batch processing: Draw polygons in batches
        const batchSize = 100; // Adjust batch size as needed
        const totalPolygons = polyResult.length;
        let startIndex = 0;

        const drawNextBatch = () => {
          const endIndex = Math.min(startIndex + batchSize, totalPolygons);
          const batch = polyResult.slice(startIndex, endIndex);

          batch.forEach((result, index) => {
            const coordinates = result.geom.coordinates[0];
            drawPolygon(newMap, coordinates, `result-${startIndex + index}`, true);
          });

          startIndex += batchSize;

          if (startIndex < totalPolygons) {
            // If there are more polygons, draw the next batch after a short delay
            setTimeout(drawNextBatch, 0);
          } else {
            // All polygons have been drawn
            setLoading(false);
          }
        };

        drawNextBatch(); // Start drawing the first batch
      } else {
        setLoading(false); // No polygons to draw, so stop loading
      }
    });

    // Event listener for polygon creation
    newMap.on("draw.create", handleDrawCreate);
    newMap.on("draw.modechange", handleModeChange);
    // Event listener for polygon modification
    newMap.on("draw.update", handleDrawUpdate);
    //     // Event listener for draw mode activation

    return () => {
      newMap.off("draw.create", handleDrawCreate);
      newMap.off("draw.update", handleDrawUpdate);
      newMap.off("draw.modechange", handleModeChange);
      newMap.remove();
    };
  }, [mapBoxKey, settings ]);

  //[mapBoxKey, settings, coordinates,drawnCoordinates, mapStyle, onCoordinatesChange,updateTotalCount ]
  const getTrimmedCoordinates = (polygon) => {
    const coordinates = polygon.geometry.coordinates[0];

    if (coordinates.length > 5) {
      // Remove additional points beyond the fourth point
      return coordinates.slice(0, 5);
    }
    // console.log(coordinates)
    return coordinates;
  };
  // Function to draw a polygon on the map
// Function to draw a polygon on the map
const drawPolygon = (map, coordinates, id, isPolyResult = false) => {
  // Check if the layer already exists, and remove it if it does
  if (map.getLayer(`polygon-${id}`)) {
    map.removeLayer(`polygon-${id}`);
  }

  // Define paint properties for line color and width
  let lineColor = isPolyResult ? "#ED7A37" : "#ef8d54"; // Red for polyResult, Blue for others
  let lineWidth = isPolyResult ? 1 : 3; // Line width 2 for polyResult, 5 for others

  // Add the new layer
  map.addLayer({
    id: `polygon-${id}`, // Ensure that the layer ID is unique
    type: "fill", // Change the layer type to 'fill' for polygons
    source: {
      type: "geojson",
      data: {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [coordinates],
        },
      },
    },
    layout: {},
    paint: {
      "fill-color": isPolyResult ? "#00ff00" : "#008000", // Green for polyResult, Green for others
      "fill-opacity": isPolyResult ? 0.5 : 0.1, // Adjust the opacity for polyResult polygons
      "fill-outline-color": lineColor, // Red outline for polyResult, Blue for others
    },
  });

  // Add line properties
  map.addLayer({
    id: `line-${id}`, // Ensure that the layer ID is unique
    type: "line", // Line layer type
    source: {
      type: "geojson",
      data: {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [coordinates],
        },
      },
    },
    layout: {},
    paint: {
      "line-color": lineColor, // Line color
      "line-width": lineWidth, // Line width
    },
  });

  // setProgress((prevProgress) => prevProgress + 1);


  // Add hover effect for polyResult polygons
  if (isPolyResult) {
    map.on("mousemove", `polygon-${id}`, function () {
      map.getCanvas().style.cursor = "pointer";
      map.setPaintProperty(`polygon-${id}`, "fill-color", "#ff0000"); // Change fill color to red on hover
    });

    // Reset the cursor and fill color when it leaves the polygon
    map.on("mouseleave", `polygon-${id}`, function () {
      map.getCanvas().style.cursor = "";
      map.setPaintProperty(`polygon-${id}`, "fill-color", "#00ff00"); // Restore fill color to green when not hovered
    });

    // Click event listener for polygons
    map.on("click", `polygon-${id}`, function (e) {
      const clickedCoordinates = e.lngLat.toArray();
      // console.log(`Clicked plot coordinates: ${clickedCoordinates}`);
      map.setPaintProperty(`polygon-${id}`, "fill-color", "#ff0000");
      // Call the onClickPlot callback function with the clicked coordinates
      onClickPlot(clickedCoordinates);
    });
  }
};

  const setMap = () => {
    setMapStyle("mapbox://styles/mapbox/streets-v11");
  };

  const setSatellite = () => {
    setMapStyle("mapbox://styles/mapbox/satellite-v9");
  };

  return (

      <div
          className={clsx(
              "flex-col-reverse mx-auto w-full relative flex justify-center items-center text-5xl font-bold h-[200px] lg:h-[500px]",
              className
          )}
          // style={{ marginTop: "50px" }}
          ref={mapContainer}
      >
        <button
            className="absolute top-6 left-2 mt-2 ml-2 px-2 py-1 bg-white border rounded-md shadow-sm text-xs"
            onClick={setMap}
        >
          Map
        </button>
        <button
            className="absolute top-6 left-[60px] mt-2 ml-2 px-2 py-1 bg-white border rounded-md shadow-sm text-xs"
            onClick={setSatellite}
        >
          Satellite
        </button>
        {loading && (
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
             <CircularProgress/>
            </div>
        )}
        {!loading && children}
        {children}
      </div>
  );
});
