import axios from 'axios';

// const apiBaseUrl = process.env.REACT_APP_BASE_URL; // Replace with your API base URL
const apiBaseUrl = "https://pgr.directmandat.com"; // Replace with your API base URL
export const baseURL = "https://pgr.directmandat.com"; // Replace with your API base URL

export const apiService = axios.create({
    baseURL: apiBaseUrl,
});


export const getLocAnns = async (time) => {
    try {
        const { data } = await apiService.get(`/anns/loca/${"month"}?ses=${JSON.parse(localStorage.getItem("ses"))}`);
        if (data.status === "OK") {
            return data;
        } else {
            return console.log("Session is Expired");
        }
    } catch (error) {
        return console.log("Fetching Error:", error);
    }
}

export const getVenteAnns = async (time) => {
    try {
        // console.log(JSON.parse(localStorage.getItem("ses")))
        const { data } = await apiService.get(`https://pgr.directmandat.com/anns/vent/month?ses=${JSON.parse(localStorage.getItem("ses"))}`);
        console.log(data)
        if (data.status === "OK") {
            return data;
        } else {
            return console.log("Session is Expired");
        }
    } catch (error) {
        return console.log("Fetching Error:", error);
    }
}

export const checkSession = async () => {
    try {
        const { data } = await apiService.get(`/age?ses=${JSON.parse(localStorage.getItem("ses"))}`);
        if (data.status === "KO") {
            return true;
        } else {
            return false;
        }
    } catch (error) {
        return console.log("Fetching Error:", error);
    }
}

export const getListCommunes = async () => {

    try {
        const { data } = await apiService.post(`/geo/getListeCommunes?sessionID=${JSON.parse(localStorage.getItem("ses"))}`);
        console.log(data,"cors")
        if (data.status === "OK") {
            return data.results;
        } else {
            return console.log("Session is Expired");
        }
    } catch (error) {
        return console.log("Fetching Error:", error);
    }
}

export const getAgency = async () => {

    try {
        const { data } = await apiService.get(`/age?ses=${JSON.parse(localStorage.getItem("ses"))}`);
        // console.log("agency: ", data)
        if (data.status === "OK") {
            return data.data;
        } else {
            return console.log("Session is Expired");
        }
    } catch (error) {
        return console.log("Fetching Error:", error);
    }
}

export const hideAnn = async (ann) => {

    console.log("HideAnn called",ann)
    try {
        const { response } = await apiService.delete(`/annonces?ses=${JSON.parse(localStorage.getItem("ses"))}&ann=${ann}`);
        if (response.status === "OK") {
            console.log("testing: ", response)
            return response.data; // Assuming the response contains the parcel data
        } else {
            console.log("Error:", response.message);
            return null;
        }
    } catch (error) {
        console.log("Fetching Error:", error);
        return null;
    }
}
export const getAnnDetail = async (ann) => {

    console.log("HideAnn called",ann)
    try {
        const { response } = await apiService.get(`/annonces?ses=${JSON.parse(localStorage.getItem("ses"))}&ann=${ann}`);
        if (response.status === "OK") {
            console.log("testing: ", response)
            return response.data; // Assuming the response contains the parcel data
        } else {
            console.log("Error:", response.message);
            return null;
        }
    } catch (error) {
        console.log("Fetching Error:", error);
        return null;
    }
}

export const getInsee = async (insee) => {
    try {
        const { data } = await apiService.get(`geo/getContourCommune?sessionID=${JSON.parse(localStorage.getItem("ses"))}&insee=${insee}`);
       console.log(data,"isne")
        if (data.status === "OK" && data.geometry != null) {
            return data.geometry.coordinates[0];
        }
        if (data.geometry == null) {
            return false
        }
        else {
            return console.log("Session is Expired");
        }
    } catch (error) {
        return console.log("Fetching Error:", error);
    }
}
export const getParcellesByInsee = async (insee, min, max) => {
    try {
        const { data } = await apiService.get(`geo/getParcellesByInsee?sessionID=${JSON.parse(localStorage.getItem("ses"))}&insee=${insee}&surf_min=${min}&surf_max=${max}`);
        if (data.status === "OK" && data.results != null) {
            return data;
        }
        else {
            return console.log("No Data found in getParcellesByInsee");
        }
    } catch (error) {
        return console.log("Fetching Error:", error);
    }
}
export const getParcellesInBox = async (sessionID, insee, surf_min, surf_max, tl_lat, tl_lon, br_lat, br_lon) => {

    console.log("box called",sessionID,insee,br_lat,br_lon,tl_lat,tl_lon,surf_max,surf_min)
    try {
        const { data } = await apiService.post(`/geo/getParcellesInBox?sessionID=${sessionID}&insee=${insee}&surf_min=${surf_min}&surf_max=${surf_max}&tl_lat=${tl_lat}&tl_lon=${tl_lon}&br_lat=${br_lat}&br_lon=${br_lon}`);
        if (data.status === "OK") {
            console.log("testing: ", data)
            return data; // Assuming the response contains the parcel data
        } else {
            console.log("Error:", data.message);
            return null;
        }
    } catch (error) {
        console.log("Fetching Error:", error);
        return null;
    }
}
